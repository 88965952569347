
import themeMixin from '~/mixins/theme'

export default {
  name: 'SimpleCardWrapper',
  mixins: [themeMixin],
  props: {
    cardData: {
      type: Object,
      required: true,
    },
  },

  computed: {
    themeComputedClass() {
      return this.getThemeClass(this.cardData.theme || this.themeColor)
    },
  },
}
