
import { getSliceComponentProps } from '@prismicio/vue/components'
import themeMixin from '@/mixins/theme'
import { slugify } from '@/modules/nuxt-media-cacher/utils'
export const LATEST_POST_COUNT = 3

export default {
  name: 'LatestPosts',
  mixins: [themeMixin],
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  async fetch() {
    let loadPageAction = ''
    switch (this.customType) {
      case 'press_tag':
        loadPageAction = 'pages/loadLatestPress'
        break
      case 'people_tag':
        loadPageAction = 'pages/loadLatestPeople'
        break
      case 'engineering_updates_tag':
        loadPageAction = 'pages/loadLatestEngineeringUpdates'
        break
      case 'insights_tag':
      default:
        loadPageAction = 'pages/loadLatestInsights'
        break
    }

    await this.$store.dispatch(loadPageAction, this.postType)
  },
  fetchOnServer: true,
  computed: {
    hasColoredCards() {
      return this.slice.variation === 'withColors'
    },
    cardLinkPath() {
      const paths = {
        engineering_updates_tag: 'blog/engineering',
        press_tag: 'news/press-releases',
        people_tag: 'blog/culture',
        insights_tag: 'news/payment-insights',
      }

      return paths[this.customType]
    },
    customType() {
      return this.slice.primary.postType.type
    },
    posts() {
      switch (this.customType) {
        case 'press_tag':
          return this.$store.getters['pages/latestPress']
        case 'engineering_updates_tag':
          return this.$store.getters['pages/latestEngineeringUpdates']
        case 'people_tag':
          return this.$store.getters['pages/latestPeople']
        case 'insights_tag':
        default:
          return this.$store.getters['pages/latestInsights']
      }
    },
    documents() {
      // Since prismic slugifies values replacing special chars
      // with dashes, we need to do the same in order to
      // be able to match a tag name against the slugified value (tag)
      const matchingKey = Object.keys(this.posts).find(
        (key) => slugify(key) === this.postType
      )
      return this.posts[matchingKey] || []
    },
    parentPagePostType() {
      const documentType = this.context?.document?.type
      const acceptedDocumentTypes = [
        'marketing_insights_post',
        'press_post',
        'people_post',
        'engineering_update_post',
      ]
      if (!acceptedDocumentTypes.includes(documentType)) {
        return
      }

      return this.context?.document.data?.post_type?.slug
    },
    filteredDocumentsByType() {
      if (!this.postType) {
        return this.documents
      }

      return this.documents.filter(
        // eslint-disable-next-line camelcase
        ({ post_type }) => slugify(post_type) === this.postType
      )
    },
    // filter out the current parent document
    // so we don't show the same page as a "see other interesting posts" feed
    filteredDocuments() {
      if (this.parentPagePostType !== this.postType) {
        return this.filteredDocumentsByType
      }

      return this.filteredDocumentsByType.filter(
        ({ id }) => id !== this.context?.document.id
      )
    },
    latestPosts() {
      return this.filteredDocuments.slice(0, LATEST_POST_COUNT)
    },
    postType() {
      // !Important!
      // slug is correct for the actual post type
      // we are not using fetchLinks to load x_post_tag
      // to get the actual object data with mapped fields (name:tag)
      return this.slice.primary.postType?.slug
    },
  },
  methods: {
    getCardTheme(cardIndex) {
      if (this.hasColoredCards) {
        const availableColors = ['teal', 'soft-white', 'ocean-blue'].map(
          this.getAccessibilityContrast
        )

        return availableColors[cardIndex % this.latestPosts.length]
      }

      return this.slice.primary.cardTheme || 'sky-blue'
    },
  },
}
