export function encodeChars(str) {
  return str
    .replace(/%/g, '%25') // Needs to be first in the chain
    .replace(/!/g, '%21')
    .replace(/@/g, '%40')
    .replace(/\^/g, '%5E')
    .replace(/#/g, '%23')
    .replace(/\$/g, '%24')
    .replace(/&/g, '%26')
    .replace(/\(/g, '%28')
    .replace(/\)/g, '%29')
    .replace(/=/g, '%3D')
    .replace(/,/g, '%2C')
    .replace(/;/g, '%3B')
    .replace(/'/g, '%27')
    .replace(/\[/g, '%5B')
    .replace(/{/g, '%7B')
    .replace(/]/g, '%5D')
    .replace(/}/g, '%7D')
}

export function slugify(text) {
  // https://gist.github.com/codeguy/6684588
  return text
    .toString()
    .toLowerCase()
    .normalize('NFD')
    .trim()
    .replace('/', '')
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '-')
    .replace(/--+/g, '-')
}

export function removeTrailingBackslash(str) {
  return str.replace(/\\+$/, '')
}

export function encodeSlashes(str) {
  return str.replace(/\//g, '\\u002F')
}
