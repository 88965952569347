
import { formatDate } from '@/utils/formatters'

export default {
  name: 'ArticleCard',
  props: {
    post: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    isFeatured: {
      type: Boolean,
      required: false,
      default: false,
    },
    featuredTheme: {
      type: String,
      required: false,
      default: 'azure',
    },
    featuredClass: {
      type: String,
      required: false,
      default: 'col-span-full md:grid-cols-2',
    },
    theme: {
      type: String,
      required: false,
      default: 'sky-blue',
    },
    path: {
      type: String,
      required: false,
      default: 'why-form3/insights',
    },
  },
  computed: {
    cardData() {
      return {
        theme: this.isFeatured ? this.featuredTheme : this.theme,
      }
    },
    formattedDate() {
      return formatDate(this.post.first_publication_date)
    },
  },
}
